import { create } from 'zustand'

type State = {
  notificationIsOpen: boolean;
}

type Action = {
  showNotification(): void;
  hideNotification(): void;
  toggleNotification(): void;
}

export const useNotification = create<State & Action>((set) => ({
  notificationIsOpen: false,
  showNotification: () => set(() => ({ notificationIsOpen: true})),
  hideNotification: () => set(() => ({ notificationIsOpen: false})),
  toggleNotification: () => set((state) => ({ notificationIsOpen: !state['notificationIsOpen']})),
}));
