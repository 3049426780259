import { Collapsible } from "@radix-ui/react-collapsible";
import { CollapsibleContent, CollapsibleTrigger } from "../../ui/collapsible";
import { Button } from "../button";
import { ChevronDown, LucideIcon } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../ui/tooltip";
import { Badge } from "../../ui/badge";
import { Link } from "react-router-dom";
import { useSidebar } from "@/app/stories/sidebar";
import { cn } from "@/view/lib/utils";

interface NavMainProps {
  itens: {
    title: string
    url: string
    icon?: LucideIcon,
    badge: string;
    items: {
      title: string
      url: string
    }[]
  }[]
}

export function NavMain({ itens }: NavMainProps) {
  const { openSidebar, isOpen, handleActiveLink, activeLink, activeSubLink } = useSidebar();

  return (
    <>
      {itens.map(item => (
        <Collapsible
          key={item.title}
          onOpenChange={() => handleActiveLink(item.title)}
          {...((item.items.length > 0 && !isOpen) && { onClick: openSidebar })} defaultOpen={(isOpen && item.title === activeLink)}
        >
          <CollapsibleTrigger className={cn(
            'relative text-sm flex items-center justify-center h-11 w-11 @[200px]/sidebar:justify-between @[200px]/sidebar:w-full m-auto hover:bg-accent rounded p-1',
            item.title === activeLink && 'bg-accent'
          )}>
            <TooltipProvider delayDuration={150}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex items-center text-sm gap-1">
                    {item.icon && <item.icon className="w-4 h-4" />}
                    <Link to={item.url} className="hidden @[200px]/sidebar:block text-left font-medium truncate max-w-28 text-xs">{item.title}</Link>
                  </div>
                </TooltipTrigger>
                <TooltipContent className="@[200px]:hidden" side="right" sideOffset={12}>{item.title}</TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <div className="flex items-center">
              {item.badge && <Badge variant="destructive" className="text-[10px] w-3 h-3 p-0 rounded-full overflow-hidden text-transparent absolute animate-pulse top-2- right-1 @[200px]/sidebar:w-fit @[200px]/sidebar:h-fit @[200px]/sidebar:relative @[200px]/sidebar:text-destructive-foreground @[200px]:animate-none	@[200px]:top-0 @[200px]:right-0 @[200px]:rounded-sm @[200px]:px-2">{item.badge}</Badge>}

              {item.items?.length > 0 && (
                <Button variant="ghost" className="hidden @[200px]:flex p-1">
                  <ChevronDown className="w-3.5 h-3.5" />
                </Button>
              )}
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent className="relative flex-col border-l-1 pl-2.5 ml-2.5 hidden @[200px]/sidebar:flex before:w-[1px] before:h-[90%] before:top-0 before:left-0 before:absolute before:bg-accent">
            {item.items.map((sublink) => (
              <Link to={sublink.url}
                key={sublink.title}
                onClick={() => handleActiveLink(item.title, sublink.title)}
                className={
                  cn(
                    "relative p-2 text-xs rounded",
                    activeSubLink === sublink.title && "font-extrabold"
                  )
                }>{sublink.title}</Link>
            ))}
          </CollapsibleContent>
        </Collapsible>
      ))}
    </>
  )
}