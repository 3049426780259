import { ErrorDialog } from "@/view/components/custom/error-modal";
import { useState } from "react";

let openModalFn: (options: { title: string; description: string }) => void;

export const ModalErrorProvider = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalOptions, setModalOptions] = useState<{ title: string; description: string }>({ title: "", description: "" });

  openModalFn = (options) => {
    setModalOptions(options);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <ErrorDialog
      title={modalOptions.title}
      description={modalOptions.description}
      isOpen={isOpen}
      onClose={closeModal}
    />
  );
};

export const openModal = (options: { title: string; description: string }) => {
  openModalFn(options);
};