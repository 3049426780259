import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export function TwoFactorGuard() {
  const { signedIn, twoFactorProcess } = useAuth();

  if(signedIn){
    return <Navigate to="/" />;
  }

  if(!twoFactorProcess){
    return <Navigate to="/login" />;
  }

  return <Outlet/>
}
