import { create } from 'zustand'

type State = {
  isOpen: boolean;
  activeLink: string;
  activeSubLink: string;
}

type Action = {
  closeSidebar(): void;
  openSidebar(): void;
  toggleSidebar(): void;
  handleActiveLink(link:string, sublink?:string): void;
}

export const useSidebar = create<State & Action>((set) => ({
  isOpen: false,
  activeLink: '',
  activeSubLink: '',
  closeSidebar: () => set({ isOpen: false }),
  openSidebar: () => set({ isOpen: true }),
  toggleSidebar: () => set((state) => ({ isOpen: !state['isOpen']})),
  handleActiveLink: (link, sublink) => set(() => ({ activeLink: link, activeSubLink: sublink})),
}));
