import { useQuery } from "@tanstack/react-query";
import { createContext, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { LaunchScreen } from "@/view/components/custom/launch-screen";
import { localStorageKeys } from "../const/localStorageKeys";
import { httpClient } from "../libs/httpClient";
import { AuthService } from "../services/authService";
import { useNavigate } from "react-router-dom";
import { setCookie, removeCookie, getCookie } from 'react-use-cookie';
import { useUser } from "../stories/user";

export interface AuthContextProps {
  signin(accessToken: string): void;
  enabled2faProcess(secondFactorToken: string): void;
  disable2faProcess(): void;
  signout(): void;
  signedIn: boolean;
  twoFactorProcess: boolean;
  data: any;
}

export const AuthContext = createContext({} as AuthContextProps);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [signedIn, setSignedIn] = useState<boolean>(() => {
    const storedAccessToken = getCookie(localStorageKeys.ACCESS_TOKEN);
    return !!storedAccessToken;
  });

  const [twoFactorProcess, setTwoFactorProcess] = useState<boolean>(() => {
    const storedTwoFactorToken = localStorage.getItem(localStorageKeys.SECOND_FACTOR_ACCESS_TOKEN);
    return !!storedTwoFactorToken;
  });

  const navigate = useNavigate();
  const { handleSetUser } = useUser()

  useLayoutEffect(() => {
    const interceptorId = httpClient.interceptors.request.use((config) => {
      const accessToken = getCookie(localStorageKeys.ACCESS_TOKEN);
      const secondFactorAccessToken = localStorage.getItem(localStorageKeys.SECOND_FACTOR_ACCESS_TOKEN);

      if (accessToken) {
        config.headers.set('Authorization', `${accessToken}`);
      }

      if (secondFactorAccessToken) {
        config.headers.set('Authorization', `${secondFactorAccessToken}`);
      }

      return config;
    });

    return () => { httpClient.interceptors.request.eject(interceptorId); };
  }, []);

  useLayoutEffect(() => {
    const interceptorId = httpClient.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (originalRequest.url === '/authentication/refresh_access_token') {
          signout()

          navigate('/login', { replace: true });

          return Promise.reject(error);
        }

        if (error.response?.status !== 401 || !signedIn) {
          return Promise.reject(error);
        }

        const { accessToken } = await AuthService.refreshToken();

        setCookie(localStorageKeys.ACCESS_TOKEN, accessToken, { days: 1, });

        return httpClient(originalRequest);
      });

    return () => { httpClient.interceptors.response.eject(interceptorId); };
  }, []);

  const { isError, isSuccess, data, isFetching, error } = useQuery({
    queryKey: ['auth/check'],
    queryFn: async () => AuthService.check(),
    staleTime: 3600,
    enabled: signedIn,
  });

  const signin = useCallback((accessToken: string) => {
    localStorage.removeItem(localStorageKeys.SECOND_FACTOR_ACCESS_TOKEN);
    setCookie(localStorageKeys.ACCESS_TOKEN, accessToken, {
      days: 1,
    });

    setTwoFactorProcess(false);
    setSignedIn(true);
  }, []);

  const signout = useCallback(() => {
    removeCookie(localStorageKeys.ACCESS_TOKEN);
    setSignedIn(false);
  }, []);

  const enable2faProcess = useCallback((secondFactorToken: string) => {
    localStorage.setItem(localStorageKeys.SECOND_FACTOR_ACCESS_TOKEN, secondFactorToken);
    setCookie(localStorageKeys.ACCESS_TOKEN, secondFactorToken, { days: 1, });

    setTwoFactorProcess(true);
  }, []);

  const disable2faProcess = useCallback(() => {
    localStorage.removeItem(localStorageKeys.SECOND_FACTOR_ACCESS_TOKEN);
    setTwoFactorProcess(false);
  }, []);

  useEffect(() => {
    if (isError) {
      signout()
      return navigate('/login', { replace: true });
    }

    handleSetUser(data?.id!, data?.storeId!);

  }, [isError, signout, error]);

  return (
    <AuthContext.Provider value={{
      signedIn: isSuccess && signedIn,
      signin,
      signout,
      enabled2faProcess: enable2faProcess,
      disable2faProcess,
      twoFactorProcess,
      data,
    }}>
      {!isFetching && children}
      {isFetching && <LaunchScreen />}
    </AuthContext.Provider>
  )
}
