import { ArrowRightFromLine } from "lucide-react";
import { Button } from "../../ui/button";
import { ScrollArea } from "../../ui/scroll-area";
import { NavMain } from "./nav-main";
import { Logo } from "@/view/assets/logo";
import { Link } from "react-router-dom";
import { useSidebar } from "@/app/stories/sidebar";
import { LogoMobile } from "@/view/assets/logo-mobile";
import { NavFooter } from "./nav-footer";
import { SidebarLabel } from "./nav-label";
import { useSidebarController } from "./useSidebarController";
import { Skeleton } from "../../ui/skeleton";
import { cn } from "@/view/lib/utils";
import { TooltipContent, TooltipProvider, TooltipTrigger } from "../../ui/tooltip";
import { Tooltip } from "@radix-ui/react-tooltip";

export function SidebarContent() {
  const { isOpen, toggleSidebar } = useSidebar();
  const { navbarLinks, isLoading } = useSidebarController();

  return (
    <nav className="w-full h-full md:max-w-[240px] top-0 left-0 fixed border border-l transition-all ease-in-out @container/sidebar group-data-[sidebar=closed]:max-w-20 bg-background mb-4">
      <header className="h-[56px] w-full border-b p-2 flex items-center">
        <Link to="/" className="flex items-center @[200px]/sidebar:justify-start justify-center mx-auto">
          <Logo />
          <LogoMobile />
        </Link>
      </header>

      <ScrollArea className="p-2.5 pb-5 flex flex-col">
        <div className="w-full flex items-center justify-center mb-6 max-sm:hidden">
          <TooltipProvider delayDuration={150}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="ghost" className="w-11 h-11 text-xs @container/sidebar-header justify-center gap-2 @[200px]:justify-start @[200px]:w-full" onClick={toggleSidebar}>
                  <ArrowRightFromLine className={cn(
                    'w-3.5 h-3.5 transition',
                    isOpen && 'rotate-180'
                  )} />
                  {isOpen && 'Ocultar menu'}
                </Button>
              </TooltipTrigger>
              <TooltipContent className="@[200px]:hidden" side="right" sideOffset={12}>{isOpen ? 'Fechar' : 'Abrir menu'}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        {isLoading && (
          <>
            <div className="space-y-2">
              <Skeleton className="w-full h-11 mt-12" />
              <Skeleton className="w-full h-11" />
              <Skeleton className="w-full h-11" />
            </div>
            <div className="space-y-2 mt-auto mb-4">
              <Skeleton className="w-full h-11 mt-12" />
              <Skeleton className="w-full h-11" />
              <Skeleton className="w-full h-11" />
            </div>
          </>
        )}


        {!isLoading && (
          <>
            <SidebarLabel>Dashboard</SidebarLabel>
            <NavMain itens={navbarLinks.navMain} />

            <SidebarLabel className=" mt-auto" >Suporte</SidebarLabel>
            <NavFooter />
          </>
        )}
      </ScrollArea>
    </nav>
  )
}