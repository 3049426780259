import { httpClient } from "../libs/httpClient";

import { checkReponse } from "../entities/auth/check";
import { enable2FaDTO, enable2FaResponse } from "../entities/auth/enabled2Fa";
import { getMaskedEmailResponse } from "../entities/auth/maskedEmail";
import { refreshTokenResponse } from "../entities/auth/refreshToken";
import { signinDTO, signinResponse } from "../entities/auth/signin";
import { verifyCodeDTO, verifyCodeResponse } from "../entities/auth/verifyCode";
import { generateQrCodeResponse } from "../entities/auth/generateQrCode";

export class AuthService {
  static async signin(params: signinDTO){
    const { data } = await httpClient.post<signinResponse>('/authentication/sign_in', params);
    return data;
  }

  static async refreshToken(){
    const { data } = await httpClient.get<refreshTokenResponse>('/authentication/refresh_access_token');
    return data;
  }

  static async verifyCode(params: verifyCodeDTO){
    const { data } = await httpClient.post<verifyCodeResponse>('/authentication/verify_code', params);
    return data;
  }

  static async send2FaValidationEmail() {
    const { data } = await httpClient.get('/authentication/send_2fa_validation_email');
    return data;
  }

  static async getMaskedEmail() {
    const { data } = await httpClient.get<getMaskedEmailResponse>('/authentication/get_masked_user_email');
    return data;
  }

  static async generateQrCode() {
    const { data } = await httpClient.get<generateQrCodeResponse>('/authentication/generating_qrcode');
    return data;
  }

  static async enable2Fa(params:enable2FaDTO) {
    const { data } = await httpClient.post<enable2FaResponse>('/authentication/enable_2fa', params);
    return data;
  }

  static async check() {
    const { data } = await httpClient.get<checkReponse>('/authentication/check');
    return data;
  }
}
