
import { Route, Routes } from "react-router-dom";
import { AuthGuard } from "./authGuard";
import { AuthLayout } from "@/view/layouts/AuthLayout";
import { TwoFactorGuard } from "./TwoFactorGuard";
import DashboardLayout from "@/view/layouts/DashboardLayout";

import { lazy } from 'react';
import NotFound from "@/view/pages/NotFound";

const Dashboard = lazy(() => import("@/view/pages/Dashboard"));
const Login = lazy(() => import("@/view/pages/Auth/Login"));
const VerifyCode = lazy(() => import("@/view/pages/Auth/VerifyCode"));
const ConfirmnEmail = lazy(() => import("@/view/pages/Auth/ConfirmEmail"));
const Enable2Fa = lazy(() => import("@/view/pages/Auth/Enable2Fa"));

export function Router() {
  return (
    <Routes>
      <Route element={<AuthGuard isPrivate={false} />}>
        <Route element={<AuthLayout />}>
          <Route element={<Login />} path="/login" />
        </Route>
      </Route>

      <Route element={<TwoFactorGuard />}>
        <Route element={<AuthLayout />}>
          <Route element={<VerifyCode />} path="/code-authentication" />
        </Route>

        <Route path="/enable-2fa" element={<Enable2Fa />} />
        <Route element={<ConfirmnEmail />} path="/confirm-email" />
      </Route>

      <Route element={<AuthGuard isPrivate={true} />}>
        <Route element={<DashboardLayout />}>
          <Route element={<Dashboard />} path="/" />
        </Route>
      </Route>
        <Route path="*" element={<NotFound />} />
    </Routes>
  );
}