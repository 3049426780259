import { useState } from 'react'
import { ChevronRight } from 'lucide-react'
import { useLocation, Link } from 'react-router-dom'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../ui/dropdown-menu'
import { Button } from './button'
import { Drawer, DrawerClose, DrawerContent, DrawerFooter, DrawerHeader, DrawerTitle, DrawerTrigger } from '../ui/drawer'
import { useIsMobile } from '@/app/hooks/useMobile'

export default function Breadcrumb() {
  const [open, setOpen] = useState(false)
  const isDesktop = useIsMobile();
  const location = useLocation();

  const breadcrumbItems = location.pathname.split('/').filter(Boolean).map((segment, index, arr) => {
    const href = `/${arr.slice(0, index + 1).join('/')}`;
    return { label: segment.charAt(0).toUpperCase() + segment.slice(1), href };
  });

  breadcrumbItems.unshift({ label: 'Dashboard', href: '/' });

  const middle = Math.ceil(breadcrumbItems.length / 2);

  const visibleItems = breadcrumbItems.length > 3 
    ? [breadcrumbItems[0], ...breadcrumbItems.slice(-middle)] 
    : breadcrumbItems;

  const hiddenItems = breadcrumbItems.length > 3 
    ? breadcrumbItems.slice(1, -middle) 
    : [];

  return (
    <nav className="flex items-center flex-wrap space-x-2 text-sm mb-8">
      {visibleItems.map((item, index) => (
        <div key={item.href} className="flex items-center">
          {index > 0 && <ChevronRight className="h-4 w-4 mx-2 text-gray-500" />}
          <Link to={item.href} className="hover:underline text-[12px]">
            {item.label}
          </Link>

          {(hiddenItems.length > 0 && index === 0) && (
            <>
              <ChevronRight className="h-4 w-4 mx-2 text-gray-500" />
              {!isDesktop ? (
                <DropdownMenu open={open} onOpenChange={setOpen}>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="sm" className="h-8 text-xs">
                      ...
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="start">
                    {hiddenItems.map((item) => (
                      <DropdownMenuItem key={item.href}>
                        <Link to={item.href} className="w-full text-xs">
                          {item.label}
                        </Link>
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              ) : (
                <Drawer open={open} onOpenChange={setOpen}>
                  <DrawerTrigger asChild>
                    <Button aria-label='Ver mais' variant="ghost" size="sm" className="h-8 text-xs">
                      ...
                    </Button>
                  </DrawerTrigger>
                  <DrawerContent>
                    <DrawerHeader className='sr-only'>
                      <DrawerTitle>Navigation</DrawerTitle>
                    </DrawerHeader>
                    <div className="p-4 space-y-2">
                      {hiddenItems.map((item) => (
                        <Link
                          key={item.href}
                          to={item.href}
                          className="block py-2 px-4 hover:bg-gray-100 rounded"
                        >
                          {item.label}
                        </Link>
                      ))}
                    </div>
                    <DrawerFooter>
                      <DrawerClose asChild>
                        <Button variant="outline">Fechar</Button>
                      </DrawerClose>
                    </DrawerFooter>
                  </DrawerContent>
                </Drawer>
              )}
            </>
          )}
        </div>
      ))}
    </nav>
  );
}
