import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import axios from "axios";
import { openModal } from "../contexts/ErrorModalContext";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
  mutationCache: new MutationCache({
    onError: (error): void => {
      if (axios.isAxiosError(error) && error.response?.data.messages) {
        const errorMessages = error.response?.data;
        const key = Object.keys(errorMessages.messages)[0];

        error.message = errorMessages.messages[key] || 'Erro desconhecido';
      }

      if (axios.isAxiosError(error) && error.response && error.response.status >= 500) {
        openModal({
          title: 'Erro 5xx',
          description: error.message,
        });
      }
    },
  }),
  queryCache: new QueryCache({
    onError: (error): void => {
      if (axios.isAxiosError(error) && error.response?.data.messages) {
        const errorMessages = error.response?.data;
        const key = Object.keys(errorMessages.messages)[0];

        error.message = errorMessages.messages[key] || 'Erro desconhecido';
      }

      if (axios.isAxiosError(error) && error.response && error.response.status >= 500) {
        openModal({
          title: `Erro 5xx`,
          description: error.message,
        });
      }
    },
  }),
})