import { TriangleAlert } from "lucide-react";
import { Button } from "../ui/button";
import { Link, useNavigate } from "react-router-dom";
import { ReloadIcon } from "@radix-ui/react-icons";

interface ErrorBoundaryAlert {
  customTitle?: string;
  customMessage?: string;
}

export function ErrorBoundaryAlert({ customMessage, customTitle }: ErrorBoundaryAlert) {
  const navigate = useNavigate();
  return (
    <div className="w-full h-full flex items-center justify-center flex-col gap-4">
      <span className="flex items-center justify-center bg-red-100 w-20 h-20 rounded-full">
        <TriangleAlert className="text-red-500 w-12 h-12" />
      </span>

      <div className="text-center max-w-96">
        <strong className="text-base">{customTitle ? customTitle : 'Ocorreu um erro ao tentar carregar a página'}</strong>
        <p className="text-sm text-muted-foreground">{customMessage ? customMessage : 'Recarregue a página e tente novamente'}</p>

        <div className="flex gap-2  mt-4">
          <Button className="w-full" variant="outline" onClick={() => { navigate(-1) }}>Voltar</Button>
          <Button className="space-x-2 w-full" variant="outline" onClick={() => window.location.reload()}>
            <ReloadIcon className="w-3 h-3" />
            <span>Recarregar página</span>
          </Button>
        </div>
      </div>


      <p className="text-xs mt-5 text-center text-muted-foreground">Se o erro persistir entre em contato com o <Link to="#" className="underline dark:text-blue-400 text-blue-600">suporte</Link></p>
    </div>
  )
}