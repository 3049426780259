import { cn } from "../lib/utils";

interface logoProps {
  className?: string
}

export const Logo = ( { className }:logoProps ) => (
<svg width="315" height="58" viewBox="0 0 315 58" fill="none" xmlns="http://www.w3.org/2000/svg" className={cn(
    'w-[65%] hidden @[200px]:block',
    className,
  )}>
<path fillRule="evenodd" clipRule="evenodd" d="m 29.381773,7.5488352 c -0.363368,-0.00143 -0.729171,0.0057 -1.095978,0.022932 C 17.57415,8.0769144 9.1023587,16.474161 8.1896049,26.865227 h 0.067168 c 0.015925,9.23e-4 0.031589,7.44e-4 0.047509,0.0017 -0.034797,0.339285 -0.06314,0.679941 -0.081912,1.023896 -0.051858,0.950196 -0.033658,1.887594 0.037679,2.811205 -0.020142,-5.42e-4 -0.040525,6.34e-4 -0.060615,0 0.1080836,1.173543 0.3170279,2.315997 0.6061458,3.425543 0.056763,0.230101 0.1208664,0.457576 0.1851202,0.684781 0.00147,0.0049 0.00344,0.0098 0.00491,0.01475 2.4160921,8.519442 10.0465801,14.951796 19.3786451,15.391188 0.374975,0.01768 0.747545,0.02314 1.118913,0.0213 0.371367,0.0018 0.743937,-0.0036 1.118912,-0.0213 9.326129,-0.439113 16.95254,-6.863442 19.37373,-15.374806 0.0018,-0.0066 0.0047,-0.01303 0.0065,-0.01966 0.009,-0.03021 0.01573,-0.06147 0.02458,-0.09174 0.05278,-0.189509 0.106408,-0.378592 0.153993,-0.570105 0.293941,-1.120335 0.505129,-2.274199 0.614337,-3.459946 -0.01958,6.18e-4 -0.03935,-5.31e-4 -0.05897,0 0.07134,-0.923611 0.08954,-1.861009 0.03768,-2.811205 -0.01877,-0.343955 -0.04711,-0.684611 -0.08191,-1.023896 0.01592,-9.23e-4 0.03159,-6.85e-4 0.04751,-0.0017 H 50.7967 C 49.883986,16.474161 41.412195,8.0769144 30.70055,7.5717707 c -0.404486,-0.019049 -0.807103,-0.02331 -1.207377,-0.019661 -0.03728,-3.406e-4 -0.07407,-0.0031 -0.1114,-0.00327 z" fill="#FFFFFF" />
<path fillRule="evenodd" clipRule="evenodd" d="m 14.8578,26.4535 c 0.8461,-4.9299 4.5903,-8.089 9.6538,-8.089 4.084,0 8.2182,2.0301 8.2182,5.908 0,1.1039 -0.2265,2.181 -2.9391,2.181 z m 9.2057,27.8624 c 13.6716,0.6448 25.4736,-9.7278 26.7206,-23.2674 -0.3994,0.0126 -0.8083,0.0096 -1.2046,0.006 -2.3572,-0.0187 -4.3921,-0.0331 -6.0464,2.917 -0.022,0.0409 -0.0739,0.1087 -0.1038,0.1448 -1.1149,1.7659 -2.395,3.392 -3.7065,4.7019 -2.027,2.0286 -3.5618,3.1498 -5.3875,3.9204 v 0.0188 l -0.2752,0.1148 c -0.0377,0.0142 -0.0771,0.0237 -0.1163,0.0346 l -0.0676,0.0204 c -1.3619,0.519 -2.9313,0.8744 -5.0715,1.1496 l -0.4576,0.0582 v -0.0362 c -1.0725,0.1258 -2.0192,0.187 -2.8777,0.187 -9.7907,0 -16.11701,-6.0447 -16.11701,-15.4014 0,-9.2009 6.12191,-15.3826 15.23161,-15.3826 7.913,0 13.4405,4.7712 13.4405,11.6024 0,4.1939 -2.0286,5.9046 -7.0025,5.9046 H 14.839 c 0.868,5.1895 4.8623,8.2702 10.775,8.2702 1.0426,0 2.2362,-0.104 3.5461,-0.3083 0.1305,-0.0127 0.2359,-0.0331 0.3428,-0.052 l 0.1856,-0.0346 c 0.3931,-0.0676 0.78,-0.1462 1.1652,-0.2294 1.7959,-0.5583 3.6782,-1.774 5.408,-3.5037 1.0646,-1.0662 1.9578,-2.2802 2.9721,-3.7772 0.2956,-0.4766 0.4969,-0.8098 0.6463,-1.0568 0.2249,-0.3757 0.3397,-0.5662 0.5111,-0.7895 2.3981,-3.1168 5.1139,-3.0726 7.9932,-3.0302 0.7579,0.0126 1.5427,0.0252 2.3321,-0.022 l 0.0802,-0.0012 C 49.7085,14.0857 39.608,4.07484 26.8374,3.4726 12.8434,2.8137 0.802458,13.6943 0.0382032,27.6977 -0.726051,41.7011 10.0694,53.657 24.0635,54.3159 Z" fill="#C20810" />
<path fillRule="evenodd" clipRule="evenodd" d="m 112.955,28.8262 c 0,5.7713 -4.455,10.2891 -10.145,10.2891 -5.6765,0 -10.1252,-4.5178 -10.1252,-10.2891 0,-5.7806 4.4487,-10.308 10.1252,-10.308 5.69,0 10.145,4.529 10.145,10.308 z M 102.81,13.4719 c -8.7005,0 -15.5174,6.7447 -15.5174,15.3543 0,8.6003 6.8169,15.3386 15.5174,15.3386 8.711,0 15.532,-6.7383 15.532,-15.3386 0,-8.6096 -6.821,-15.3543 -15.532,-15.3543 z" fill="currentColor" />
<path fillRule="evenodd" clipRule="evenodd" d="m 155.659,13.4719 c -2.619,0 -5.975,0.6275 -8.162,1.5254 -1.165,0.4812 -2.013,1.0645 -2.623,1.8145 -2.115,-2.1873 -5.042,-3.3399 -8.492,-3.3399 -2.601,0 -5.957,0.6275 -8.16,1.5254 -3.019,1.2314 -4.308,3.1545 -4.308,6.4364 v 22.7311 h 0.404 c 1.898,0 3.44,-0.3036 4.719,-0.9232 l 0.23,-0.1115 V 22.6777 c 0,-1.832 0.567,-2.7489 2.091,-3.3779 1.212,-0.4938 3.181,-0.854 4.683,-0.854 4.476,0 7.15,3.2003 7.15,8.5595 v 17.1595 h 0.403 c 1.898,0 3.442,-0.3036 4.721,-0.9232 l 0.228,-0.1115 V 22.6777 c 0,-1.8273 0.582,-2.7757 2.077,-3.3779 1.189,-0.4844 3.206,-0.854 4.696,-0.854 4.478,0 7.152,3.2003 7.152,8.5595 v 17.1595 h 0.405 c 1.879,0 3.424,-0.3036 4.719,-0.9232 l 0.228,-0.1102 V 26.6262 C 167.819,18.636 163.046,13.4719 155.659,13.4719 Z" fill="currentColor" />
<path fillRule="evenodd" clipRule="evenodd" d="m 197.383,28.8088 c 0,6.3468 -3.609,10.2909 -9.423,10.2909 -1.918,0 -4.224,-0.4167 -5.611,-1.0143 -1.415,-0.6022 -2.046,-1.4453 -2.046,-2.7286 V 22.6777 c 0,-1.8211 0.596,-2.7365 2.199,-3.3765 1.291,-0.5205 3.297,-0.8554 5.117,-0.8554 6.114,0 9.764,3.8731 9.764,10.363 z m -9.369,-15.3369 c -2.905,0 -6.372,0.6055 -8.624,1.5066 -3.07,1.2282 -4.438,3.2189 -4.438,6.4536 v 34.8144 h 0.406 c 1.895,0 3.439,-0.3003 4.719,-0.9229 l 0.226,-0.1118 V 42.1503 c 2.159,1.2691 5.154,2.0145 8.182,2.0145 8.547,0 14.288,-6.1739 14.288,-15.356 0,-9.3189 -5.791,-15.3369 -14.759,-15.3369 z" fill="currentColor" />
<path fillRule="evenodd" clipRule="evenodd" d="M 213.857,34.3111 V 0 h -0.404 c -1.9,0 -3.444,0.301776 -4.723,0.922901 L 208.504,1.0331 v 33.6193 c 0,4.3812 0.969,7.5026 3.142,10.1225 l 0.165,0.1981 0.25,-0.0661 c 1.672,-0.4419 2.999,-1.1071 4.057,-2.0349 l 0.274,-0.2422 -0.211,-0.2972 c -1.675,-2.3352 -2.324,-4.5855 -2.324,-8.0215 z" fill="currentColor" />
<path fillRule="evenodd" clipRule="evenodd" d="m 243.177,24.2108 c 0,1.1008 -0.227,2.1732 -2.932,2.1732 h -14.892 c 0.842,-4.9143 4.576,-8.0639 9.627,-8.0639 4.072,0 8.197,2.0222 8.197,5.8907 z m -8.124,-10.7389 c -9.086,0 -15.189,6.1627 -15.189,15.3369 0,9.3268 6.307,15.356 16.069,15.356 2.801,0 5.842,-0.5018 8.569,-1.4092 l 0.277,-0.0926 v -0.2909 c 0,-1.6259 -0.274,-3.0241 -0.813,-4.1595 l -0.151,-0.3144 -0.333,0.1007 c -2.364,0.7234 -5.201,1.1716 -7.402,1.1716 -5.899,0 -9.885,-3.0712 -10.749,-8.2449 h 16.139 c 4.962,0 6.986,-1.7047 6.986,-5.8877 0,-6.809 -5.512,-11.566 -13.405,-11.566 z" fill="currentColor" />
<path fillRule="evenodd" clipRule="evenodd" d="m 260.803,4.72543 h -0.404 c -1.899,0 -3.442,0.30201 -4.722,0.92314 l -0.227,0.11159 v 8.25274 h -4.442 l -0.113,0.2232 c -0.582,1.181 -0.851,2.5679 -0.851,4.3653 v 0.4042 h 5.406 v 14.024 c 0,2.2613 0.073,4.4472 0.939,6.3357 1.82,3.966 6.177,4.7994 9.509,4.7994 0.732,0 1.221,-0.044 1.806,-0.154 l 0.201,-0.0364 0.088,-0.184 c 0.561,-1.159 0.835,-2.5363 0.835,-4.2157 v -0.4011 l -0.399,-0.0047 c -5.378,-0.052 -7.626,-0.7913 -7.626,-5.2383 V 19.0056 h 7.226 l 0.109,-0.2297 c 0.585,-1.2234 0.868,-2.6498 0.868,-4.3591 v -0.4041 l -8.203,-0.0012 z" fill="currentColor" />
<path fillRule="evenodd" clipRule="evenodd" d="m 296.904,28.8262 c 0,5.7713 -4.455,10.2891 -10.144,10.2891 -5.677,0 -10.127,-4.5178 -10.127,-10.2891 0,-5.7806 4.45,-10.308 10.127,-10.308 5.688,0 10.144,4.529 10.144,10.308 z M 286.76,13.4719 c -8.703,0 -15.516,6.7447 -15.516,15.3543 0,8.6003 6.813,15.3386 15.516,15.3386 8.71,0 15.533,-6.7383 15.533,-15.3386 0,-8.6096 -6.823,-15.3543 -15.533,-15.3543 z" fill="currentColor" />
<path fillRule="evenodd" clipRule="evenodd" d="m 310.028,11.0812 c 0,-0.6352 -0.301,-1.0252 -1.118,-1.0252 h -1.539 c -0.061,0 -0.093,0.0284 -0.093,0.0599 v 1.8727 c 0,0.0613 0.032,0.0895 0.093,0.0895 h 1.539 c 0.817,0 1.118,-0.3616 1.118,-0.9969 z m -3.775,4.1389 V 9.66138 c 0,-0.30195 0.182,-0.48279 0.484,-0.48279 h 2.205 c 1.297,0 2.113,0.75625 2.113,1.90261 0,0.876 -0.453,1.5411 -1.299,1.7818 l 1.089,2.1449 c 0.058,0.121 0.089,0.2122 0.089,0.3317 0,0.2422 -0.272,0.423 -0.514,0.423 -0.27,0 -0.36,-0.091 -0.451,-0.2719 l -1.239,-2.5066 h -1.359 c -0.061,0 -0.093,0.0299 -0.093,0.0896 v 2.145 c 0,0.3318 -0.209,0.5439 -0.481,0.5439 -0.332,0 -0.544,-0.2104 -0.544,-0.5439 z m 7.098,-2.7487 c 0,2.84 -2.085,4.9235 -4.864,4.9235 -2.808,0 -4.892,-2.0835 -4.892,-4.9235 0,-2.81011 2.084,-4.89362 4.892,-4.89362 2.779,0 4.864,2.08351 4.864,4.89362 z m -10.691,0 c 0,-3.29285 2.506,-5.79959 5.827,-5.79959 3.323,0 5.798,2.50674 5.798,5.79959 0,3.3227 -2.475,5.8293 -5.798,5.8293 -3.321,0 -5.827,-2.5066 -5.827,-5.8293 z" fill="currentColor" />
<path fillRule="evenodd" clipRule="evenodd" d="M 60.2033,28.9502 C 60.2033,20.0952 66.3896,14 75.8736,14 c 2.8322,0 5.8247,0.5395 8.2229,1.3335 0,1.4799 -0.2359,2.7959 -0.758,3.9139 -2.0742,-0.6322 -4.8859,-1.0818 -7.1393,-1.0818 -6.9632,0 -11.4167,4.293 -11.4167,10.7688 0,6.5088 4.4928,10.7296 11.272,10.7296 2.4327,0 5.301,-0.5411 7.4821,-1.2252 0.5426,1.1731 0.7753,2.5255 0.7753,4.0586 -2.5224,0.8461 -5.5527,1.3872 -8.4005,1.3872 C 66.3692,43.8834 60.2033,37.8068 60.2033,28.9502 Z" fill="currentColor" />
</svg>
)


