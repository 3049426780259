import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Sentry.init({
  enabled: import.meta.env.PROD,
  dsn: "https://e4bd8dd7ac5f8312214a4e60da748e2b@o4508002559131648.ingest.us.sentry.io/4508002561228800",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  environment: import.meta.env.MODE,
  tracesSampleRate: 0.0,
  tracePropagationTargets: [/^\//, /^https:\/\/intranet\.ecompleto\.com\.br/],
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,
});
