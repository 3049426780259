import { useIsMobile } from "@/app/hooks/useMobile";
import { SidebarContent } from "./content";
import { useSidebar } from "@/app/stories/sidebar";
import { Sheet, SheetContent } from "../sheet";

export function SidebarLayout() {
  const isMobile = useIsMobile();
  const { isOpen, toggleSidebar } = useSidebar();

  if (isMobile) {
    return (
      <Sheet open={isOpen} defaultOpen={false} onOpenChange={toggleSidebar}>
        <SheetContent className="w-[400px] sm:w-[540px]">
          <SidebarContent />
        </SheetContent>
      </Sheet>
    );
  }

  return (
    <aside data-sidebar={isOpen ? 'open' : 'closed'} className="w-full h-full max-w-[240px] relative hidden lg:block transition-all ease-in-out z-10 data-[sidebar=closed]:max-w-20 group">
      <SidebarContent />
    </aside>
  );
}