import { Collapsible } from "@radix-ui/react-collapsible";
import { CollapsibleTrigger } from "../../ui/collapsible";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../ui/tooltip";
import { Badge } from "../../ui/badge";
import { Link } from "react-router-dom";
import { useSidebar } from "@/app/stories/sidebar";
import { cn } from "@/view/lib/utils";
import { useSidebarController } from "./useSidebarController";

export function NavFooter() {
  const { handleActiveLink, activeLink } = useSidebar();
  const { navFooter } = useSidebarController();

  return (
    <>
      {navFooter.map(item => (
        <Link key={item.title} to={item.url}>
          <Collapsible
            onOpenChange={() => handleActiveLink(item.title)}
          >
            <CollapsibleTrigger className={cn(
              'relative text-sm flex items-center justify-center h-11 w-11 @[200px]/sidebar:justify-between @[200px]/sidebar:w-full m-auto hover:bg-accent rounded p-1',
              item.title === activeLink && 'bg-accent'
            )}>
              <TooltipProvider delayDuration={150}>
                <Tooltip>
                  <TooltipTrigger>
                    <div className="flex items-center text-sm gap-1">
                      {item.icon && <item.icon className="w-4 h-4" />}
                      <span className="hidden @[200px]/sidebar:block text-left font-medium truncate max-w-28 text-xs">{item.title}</span>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent className="@[200px]:hidden" side="right" sideOffset={12}>{item.title}</TooltipContent>
                </Tooltip>
              </TooltipProvider>

              <div className="flex items-center">
                {item.badge && <Badge variant="destructive" className="text-[10px] w-3 h-3 p-0 rounded-full overflow-hidden text-transparent absolute animate-pulse top-2 right-1 @[200px]/sidebar:w-fit @[200px]/sidebar:h-fit @[200px]/sidebar:relative @[200px]/sidebar:text-destructive-foreground @[200px]:animate-none	@[200px]:top-0 @[200px]:right-0 @[200px]:rounded-sm @[200px]:px-2">{item.badge}</Badge>}
              </div>
            </CollapsibleTrigger>
          </Collapsible>
        </Link>
      ))}
    </>
  )
}