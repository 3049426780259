import { Book, Headset, ListOrdered, LucideIcon, MessageSquare, Rabbit, SquareChartGantt } from "lucide-react"

export interface navMainProps {
  itens: {
    title: string
    url: string
    icon?: LucideIcon,
    badge: string;
    items: {
      title: string
      url: string
    }[]
  }[]
};

const NAV_FOOTER = [
  {
    title: "Suporte",
    url: "https://www.ecompleto.com.br/admin/home/principal.php",
    icon: Headset,
    badge: null,
  },
  {
    title: "Manuais",
    url: "https://www.ecompleto.com.br/admin/suporte/manuais_p.php",
    icon: Book,
    badge: null,
  },
  {
    title: "Abrir chamado",
    url: "https://www.ecompleto.com.br/admin/suporte/chamado_suport_i.php",
    icon: MessageSquare,
    badge: null,
  },
];

const NAV_MAIN = {
  navMain: [
    {
      title: "Minhas vendas",
      url: "https://www.ecompleto.com.br/admin/suporte/chamado_suport_i.php",
      badge: '',
      icon: ListOrdered,
      items: [
        {
          title: "Pedidos",
          url: "#",
        },
        {
          title: "Tele vendas",
          url: "#",
        },
      ],
    },
    {
      title: "Produtos",
      url: "#",
      icon: SquareChartGantt,
      badge: 'Novo',
      items: [
        {
          title: "Cadastrar produto",
          url: "#",
          icon: Rabbit,
          description: "Our fastest model for general use cases.",
        },
      ],
    },
  ],
}

export function useSidebarController() {
  const isLoading = false;

  return {
    isLoading,
    navbarLinks: NAV_MAIN,
    navFooter: NAV_FOOTER,
  }
}