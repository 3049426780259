import { cn } from "@/view/lib/utils";
import React from "react";

export const SidebarLabel = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "text-xs font-medium text-transparent @[200px]/sidebar:text-muted-foreground mt-6 mb-2",
        className,
      )}
      {...props}
    />
  )
});
SidebarLabel.displayName = "SidebarLabel";