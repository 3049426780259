import { useTheme } from "@/app/contexts/ThemeContext";
import { Bell, Menu, Moon, Sun } from "lucide-react";
import { Button } from "../ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../ui/dropdown-menu";
import { Avatar, AvatarFallback } from "../ui/avatar";
import { useAuth } from "@/app/hooks/useAuth";
import { useSidebar } from "@/app/stories/sidebar";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../ui/sheet";
import { useNotification } from "@/app/stories/notifications";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { Suspense } from "react";
import Loading from "./loading";

export default function Header() {
  const { setTheme } = useTheme();
  const { signout } = useAuth();
  const { toggleSidebar } = useSidebar();
  const { notificationIsOpen, toggleNotification } = useNotification();

  return (
    <>
      <header className="w-full px-8 py-2 border-b flex items-center justify-end gap-4 sticky top-0 bg-card z-10">
        <Button variant="outline" size="icon" className="mr-auto lg:hidden" onClick={toggleSidebar}>
          <Menu className="h-4 w-4 rotate-0 scale-100 transition-all" />
          <span className="sr-only">Abrir menu</span>
        </Button>

        <Button variant="outline" size="icon" className="relative" onClick={toggleNotification}>
          <Bell className="h-4 w-4 rotate-0 scale-100 transition-all" />
          <span className="sr-only">Notificações</span>

          <div className="absolute -top-1 -right-1">
            <span className="relative flex h-3 w-3">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
            </span>
          </div>
        </Button>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="icon">
              <Sun className="h-4 w-4 rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
              <Moon className="absolute h-4 w-4 rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
              <span className="sr-only">Trocar tema</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={() => setTheme("light")}>
              Claro
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setTheme("dark")}>
              Escuro
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setTheme("system")}>
              Sistema
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>

        <DropdownMenu>
          <DropdownMenuTrigger>
            <Avatar>
              <AvatarFallback>V</AvatarFallback>
            </Avatar>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem className="cursor-pointer" onClick={signout}>Sair</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </header>

      <Sheet open={notificationIsOpen} onOpenChange={toggleNotification}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Notificações</SheetTitle>

            <Tabs defaultValue="overview" className="w-full">
              <TabsList className="my-6 w-full">
                <TabsTrigger className="w-full" value="overview">Lidas</TabsTrigger>
                <TabsTrigger className="w-full" value="analytics">Não lidas</TabsTrigger>
              </TabsList>
              <Suspense fallback={<Loading />} >
                <TabsContent className="w-full" value="overview">
                </TabsContent>
                <TabsContent value="analytics">
                </TabsContent>
              </Suspense>
            </Tabs>
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </>
  )
}