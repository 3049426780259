import { Outlet } from "react-router-dom";
import Header from "../components/custom/header";
import { SidebarLayout } from "../components/custom/sidebar/layout";
import { Suspense } from "react";
import Loading from "../components/custom/loading";

import { ErrorBoundary } from "react-error-boundary";
import { ErrorBoundaryAlert } from "../components/custom/error-message";
import Breadcrumb from "../components/custom/breadcrumb";

export default function DashboardLayout() {
  return (
    <main className="flex h-full">
      <SidebarLayout />
      <div className="flex flex-col w-full">
        <Header />
        <div className="px-8 container mt-11">
        <Breadcrumb />
          <ErrorBoundary fallback={<ErrorBoundaryAlert />}>
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>
    </main>
  )
}