import { Outlet } from 'react-router-dom';
import Banner from '../assets/banner-login.webp'
import { Logo } from '../assets/logo';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryAlert } from '../components/custom/error-message';
import { Suspense } from 'react';
import Loading from '../components/custom/loading';

export function AuthLayout() {
  return (
    <div className='h-full flex items-center justify-center container'>
      <section className='flex gap-44 h-full flex-1 max-h-[730px]'>
        <aside className='hidden flex-1 items-center justify-center h-full lg:flex'>
          <img className='w-auto h-[90%] lg:h-full' src={Banner} alt="" />
        </aside>

        <main className='flex-1 flex flex-col justify-center sm:mb-16 lg:justify-start gap-10 lg:gap-[130px]'>
          <Logo className='block max-w-52' />
          <ErrorBoundary fallback={<ErrorBoundaryAlert />}>
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </main>
      </section>
    </div>
  );
}