import { useNavigate } from "react-router-dom";
import { Button } from "../components/ui/button";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="w-full h-full flex items-center justify-center flex-col container">
      <div className="text-left max-w-2xl w-full">
        <strong>Página não encontrada.</strong>
        <h1 className="text-8xl font-black">404</h1>
      </div>

      <div className="mt-8  mx-auto max-w-2xl w-full">
        <p className="text-base font-semibold">A página que você está procurando não existe ou foi removida.</p>

        <ul className="list-disc clear-start pl-5 my-2">
          <li>
            <p className="text-sm">Verifique o endereço digitado e tente novamente.</p>
          </li>
          <li>
            <p className="text-sm">Se você precisar de ajuda, entre em <Button onClick={() => navigate('/suporte', { replace: true })} variant="link" className="p-0 text-sm">contato conosco</Button>.</p>
          </li>
        </ul>
        <p className="mt-8">Enquanto isso, você pode voltar para a página <Button onClick={() => navigate('/login', { replace: true })} variant="link" className="p-0 text-base">anterior</Button> e continuar navegando!</p>
      </div>
    </div>
  )
}