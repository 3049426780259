import { create } from 'zustand'

type State = {
  userId: number;
  storeId: number;
}

type Action = {
  handleSetUser(userId:number, storeId:number): void;
}

export const useUser = create<State & Action>((set) => ({
  userId: 0,
  storeId: 0,
  handleSetUser: (link, sublink) => set(() => ({ userId: link, storeId: sublink})),
}));
