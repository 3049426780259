import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "../ui/dialog";

interface ManualDialogProps {
  title: string;
  description: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ErrorDialog = ({ title, description, isOpen, onClose }: ManualDialogProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogTrigger asChild>
        <button className="hidden"></button>
      </DialogTrigger>
      <DialogContent className="bg-red-500 text-white border-0">
        <DialogTitle>{title}</DialogTitle>
        <DialogDescription className="text-white">{description}</DialogDescription>
      </DialogContent>
    </Dialog>
  );
};
