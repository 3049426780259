import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

interface AuthGuardProps {
  isPrivate?: boolean,
}

export function AuthGuard({ isPrivate }: AuthGuardProps) {
  const { signedIn } = useAuth();

  if(!signedIn && isPrivate){
    return <Navigate to="/login" replace={true} />;
  }

  if(signedIn && !isPrivate){
    return <Navigate to="/" replace={true} />;
  }

  return <Outlet />
}
